/** Stops scrolling of body while modals etc are open */
const freezeBody = () => {
  if (!document.body.classList.contains('freeze')) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.classList.add('freeze');
  }
};

/** Restores scrolling on the body */
const unfreezeBody = () => {
  const { top } = document.body.style;
  document.body.style.position = 'relative';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(top || '-0', 10) * -1);
  document.body.classList.remove('freeze');
};

export {
  freezeBody,
  unfreezeBody,
};
