import React from 'react';
import PropTypes from 'prop-types';
import styles from './scss/search-box.module.scss';

/** Search input field */
const SearchBox = ({
  placeholder,
  value,
  onChange,
}) => (
  <div className={styles.container}>
    <input type="text" placeholder={placeholder} value={value} onChange={onChange} />
  </div>
);

SearchBox.propTypes = {
  /** Placeholder text for input */
  placeholder: PropTypes.string,
  /** Input value */
  value: PropTypes.string,
  /** Callback on input change */
  onChange: PropTypes.func,
}

export default SearchBox;
