import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';
import NotFound from 'bundles/not-found';
import VoteCount from 'components/vote-count';
import Author from 'components/author';
import styles from './scss/detail.module.scss';

/** Fact detail screen */
const Detail = ({
  fact,
  backLink,
}) => {
  if (_isEmpty(fact)) return (
    <NotFound />
  );

  const name = fact.user?.name?.first;
  const surname = fact.user?.name?.last;

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.back}>
          <Link to={backLink}>Back</Link>
        </div>
        <div className={styles.upvotes}>
          <span>Up-votes:</span> <VoteCount count={fact.upvotes} />
        </div>
      </div>

      <div className={styles.body}>
        <p>{fact.text}</p>
      </div>

      <Author name={name} surname={surname} />
    </section>
  );
}

Detail.defaultProps = {
  fact: {},
};

Detail.propTypes = {
  /** Fact info */
  fact: PropTypes.shape({
    /** Unique identifier for fact */
    _id: PropTypes.string,
    /** Author data */
    user: PropTypes.shape({
      /** Author ID */
      _id: PropTypes.string,
      /** Author name */
      name: PropTypes.shape({
        /** First name of fact author */
        first: PropTypes.string,
        /** Surname name of fact author */
        last: PropTypes.string,
      }),
    }),
    /** Fascinating cat fact */
    text: PropTypes.string,
    /** Number of up-votes for fact */
    upvotes: PropTypes.number,
  }),
};

export default Detail;