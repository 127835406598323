import { connect } from 'react-redux';
import STATUS from 'enum/status';
import fetchFactsAction from 'store/facts/facts.action';
import App from '.';

const mapStateToProps = ({ facts = {} }) => ({
  loading: facts.status !== STATUS.SUCCESS && facts.status !== STATUS.FAILURE,
  error: facts.status === STATUS.FAILURE,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(fetchFactsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
