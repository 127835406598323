import queryString from 'query-string';

/**
 * Get search from querystring
 *
 * @param {string} query Querystring
 * @returns {string} Value of search in querystring
 */
const getSearch = query => {
  try {
    return queryString.parse(query).search;
  } catch (e) {
    return undefined;
  }
};

export default getSearch;