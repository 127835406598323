import React from 'react';
import ErrorMessage from 'components/error-message';
import styles from './scss/not-found.module.scss';

/** 404 screen */
const NotFound = () => (
  <section className={styles.container}>
    <ErrorMessage
      title="404"
      message="Are you lost, kitten?"
    />
  </section>
);

export default NotFound;