import queryString from 'query-string';

/**
 * get return URL from querystring
 *
 * @param {string} query Querystring
 * @returns {string} Value of returnTo in querystring
 */
const getReturnTo = query => {
  try {
    return queryString.parse(query).returnTo;
  } catch (e) {
    return undefined;
  }
};

export default getReturnTo;