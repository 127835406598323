import React from 'react';
import PropTypes from 'prop-types';
import styles from './scss/vote-count.module.scss';


/** Up-vote count display. */
const VoteCount = ({
  count,
}) => (
  <div className={styles.count}>{count}</div>
);

VoteCount.propTypes = {
  /** Number of votes */
  count: PropTypes.number,
};


export default VoteCount;
