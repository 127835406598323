import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import VoteCount from 'components/vote-count';
import styles from './scss/list-item.module.scss';


/** A fascinating cat fact. */
const ListItem = ({
  className,
  name,
  surname,
  fact,
  votes,
  link,
  authorLink,
}) => (
  <li className={`${styles.container} ${className}`}>
    <Link to={link} className={styles.fact}>
      <p>{fact}</p>
    </Link>

    <div className={styles['fact-meta']}>
      <div className={styles.author}>
        <Link to={authorLink}>{name} {surname}</Link>
      </div>
      <div className={styles.upvotes}>
        <VoteCount count={votes} />
      </div>
    </div>
  </li>
);

ListItem.defaultProps = {
  className: '',
};

ListItem.propTypes = {
  /** Additional CSS class(es) */
  className: PropTypes.string,
  /** First name of fact author */
  name: PropTypes.string,
  /** Surname name of fact author */
  surname: PropTypes.string,
  /** Fascinating cat fact */
  fact: PropTypes.string,
  /** Number of up-votes for fact */
  votes: PropTypes.number,
  /** Link from fact */
  link: PropTypes.string,
  /** Link from author */
  authorLink: PropTypes.string,
};


export default ListItem;
