import { RESET_STORE } from './root-reducer.const';

const rootReducer = reducers => (state = {}, action) => {
  switch (action.type) {
    case RESET_STORE:
      // whitelist of reducers whose state should survive a reset
      return reducers({
        router: state.router,
      }, action);

    default:
      return reducers(state, action);
  }
};

export { rootReducer as default };
