import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _range from 'lodash.range';
import styles from './scss/pagination.module.scss';


const getPaginationNumbers = (currentPage, pageCount, pageDelta) => {
  const minimumLeftNumber = currentPage - pageDelta;
  const maximumRightNumber = currentPage + pageDelta + 1;

  const minOverflow = Math.max(pageDelta - currentPage + 1, 0);
  const maxOverflow = Math.max((currentPage) - pageCount + pageDelta, 0);

  return _range(1, pageCount + 1)
    .filter(i => i && i >= minimumLeftNumber - maxOverflow && i < maximumRightNumber + minOverflow);
};

/** A clickable pagination link that includes numbers. Page numbers start at 1. */
const Pagination = ({
  pageCount,
  pageNumber,
  pageDelta,
  handlePaginationChange,
}) => {
  if (!(pageCount > 1) || (pageNumber > pageCount)) return null;

  const pageNumbers = getPaginationNumbers(pageNumber, pageCount, pageDelta);

  return (
    <div className={styles['pagination-wrapper']}>
      <div className={styles['button-container']}>
        <button
          className={styles['button-prev']}
          disabled={pageNumber <= 1}
          onClick={() => { handlePaginationChange(pageNumber - 1); }}
        >
          Previous
        </button>
      </div>

      <div className={styles['number-container']}>
        <ul className={styles.pagination} role="navigation" aria-label="Pagination">
          {pageDelta > 0 && (
            <Fragment>
              {pageNumbers[0] > 1 && (
                <li key="before" className={styles.more}><span>...</span></li>
              )}
              {pageNumbers.map((i) => {
                const isCurrentPage = i === pageNumber;
                return (
                  <li key={i}>
                    <button
                      className={`${styles.button} pagination-page ${isCurrentPage ? `${styles['current-page']} current` : ''}`}
                      aria-label={`Page ${i}`}
                      onClick={() => { if (!isCurrentPage) { handlePaginationChange(i); } }}
                    >
                      {i}
                    </button>
                  </li>
                );
              })}
              {pageNumbers[pageNumbers.length - 1] !== pageCount && (
                <li key="after" className={styles.more}><span>...</span></li>
              )}
            </Fragment>
          )}
        </ul>
      </div>

      <div className={styles['button-container']}>
        <button
          className={styles['button-next']}
          disabled={pageCount <= pageNumber}
            onClick={() => { handlePaginationChange(pageNumber + 1); }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  pageDelta: 0,
  onPaginationChange: () => { },
};

Pagination.propTypes = {
  /** Page count */
  pageCount: PropTypes.number,
  /** Page that is currently shown */
  pageNumber: PropTypes.number,
  /**
   * How many page numbers to show around the current page.
   * If pageDelta is 0, page numbers are not shown.
   */
  pageDelta: PropTypes.number,
  /**
   * An action that occurs when pagination changes.
   * Clicks on the current page number are ignored.
   */
  handlePaginationChange: PropTypes.func,
};

export default Pagination;
