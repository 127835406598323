import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { freezeBody, unfreezeBody } from 'helpers/dom';

const root = document.querySelector('body');

/** Component to wrap components which need to be appended to the DOM above everything else */
class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    freezeBody();
    root.appendChild(this.el);
  }

  componentWillUnmount() {
    unfreezeBody();
    root.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }
}

Portal.propTypes = {
  /** Modal content */
  children: PropTypes.node,
};

export default Portal;
