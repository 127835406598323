import { connect } from 'react-redux';
import config from 'config';
import getSearch from 'helpers/get-search';
import Facts from '.';

const mapStateToProps = ({ facts = {}, router = {} }) => ({
  factList: facts.facts || [],
  pageSize: config.pageSize,
  search: getSearch(router.location?.search) || '',
});

export default connect(mapStateToProps)(Facts);
