import React from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';
import ListItem from 'components/list-item';
import ErrorMessage from 'components/error-message';
import styles from './scss/list.module.scss';


/** List of list-items */
const List = ({
  className,
  list,
}) => {
  if (list.length === 0) return (
    <div className={styles.empty}>
      <ErrorMessage
        title="¯\_(ツ)_/¯"
        message="No matching cat facts found."
      />
    </div>
  );

  return (
    <div className={`${styles.container} ${className}`}>
      <ul>
        {list.map(fact => {
          const name = fact.user?.name?.first;
          const surname = fact.user?.name?.last;

          return (
            <ListItem
              key={fact._id}
              name={name}
              surname={surname}
              fact={fact.text}
              votes={fact.upvotes}
              link={routes.FACT_DETAIL.make(
                fact._id,
                `${window.location.pathname}${window.location.search}`)
              }
              authorLink={routes.FACTS.make(1, `${name} ${surname}`)}
            />
          );
        })}
      </ul>
    </div>
  );
};

List.defaultProps = {
  className: '',
  list: [],
};

List.propTypes = {
  /** Additional CSS class(es) */
  className: PropTypes.string,
  /** List of items */
  list: PropTypes.arrayOf(PropTypes.shape({
    /** Unique identifier for fact */
    _id: PropTypes.string,
    /** Author data */
    user: PropTypes.shape({
      /** Author ID */
      _id: PropTypes.string,
      /** Author name */
      name: PropTypes.shape({
        /** First name of fact author */
        first: PropTypes.string,
        /** Surname name of fact author */
        last: PropTypes.string,
      }),
    }),
    /** Fascinating cat fact */
    text: PropTypes.string,
    /** Number of up-votes for fact */
    upvotes: PropTypes.number,
  })),
};


export default List;
