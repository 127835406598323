import React from 'react';
import PropTypes from 'prop-types';
import styles from './scss/loader.module.scss';


/** Renders a spinner inside a container. */
const Loader = ({ className }) => (
  <div className={`${styles['load-wrapper']} ${className}`}>
    <div className={styles.loader} />
  </div>
);

Loader.defaultProps = {
  className: '',
};

Loader.propTypes = {
  /** Additional CSS class(es) */
  className: PropTypes.string,
};


export default Loader;
