import React from 'react';
import ErrorMessage from 'components/error-message';
import styles from './scss/error.module.scss';


/** Renders a full screen error message. */
const Error = () => (
  <div className={`${styles['error-wrapper']}`}>
    <ErrorMessage title="Oops" message="Something went horribly wrong." />
  </div>
);

export default Error;
