import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _chunk from 'lodash.chunk';
import { useParams, useHistory } from 'react-router-dom';
import routes from 'routes';
import SearchForm from 'components/search-form';
import Pagination from 'components/pagination';
import List from 'components/list';
import styles from './scss/facts.module.scss';

/** Main fact screen */
const Facts = ({
  factList,
  pageSize,
  search,
}) => {
  const [filteredList, setFilteredList] = useState([]);
  const [listPage, setListPage] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const { page } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!search || search === '') {
      setFilteredList(factList);
    } else {
      setFilteredList(factList.filter(item => (
        // Join the strings together to allow for name and surname combinations in search
        `${item.user?.name?.first || ''} ${item.user?.name?.last || ''} ${item.text || ''}`
          .toLowerCase().includes(search.toLowerCase().trim())
      )));
    }
  }, [factList, search]);

  useEffect(() => {
    if (pageSize) {
      const chunkedList = _chunk(filteredList, pageSize);
      const currPage = page || 1;
      const numPages = chunkedList.length;

      setPageCount(numPages);
      setListPage(chunkedList[Math.max(currPage - 1, 0)]);
    } else {
      setListPage(filteredList);
    }
  }, [filteredList, pageSize, page, history, search]);

  return (
    <section className={styles.container}>
      <div className={styles.search}>
        <SearchForm searchText={search} handleSearch={value => history.push(routes.FACTS.make(1, value))} />
      </div>

      {search && (
        <h1 className={styles['search-header']}>Search results for "{search}"</h1>
      )}
      <List list={listPage} />

      <Pagination
        pageCount={pageCount}
        pageNumber={parseInt(page, 10) || 1}
        pageDelta={2}
        handlePaginationChange={page => history.push(routes.FACTS.make(page, search))}
      />
    </section>
  );
}

Facts.defaultProps = {
  factList: [],
};

Facts.propTypes = {
  /** List of items */
  factList: PropTypes.arrayOf(PropTypes.shape({
    /** Unique identifier for fact */
    _id: PropTypes.string,
    /** Author data */
    user: PropTypes.shape({
      /** Author ID */
      _id: PropTypes.string,
      /** Author name */
      name: PropTypes.shape({
        /** First name of fact author */
        first: PropTypes.string,
        /** Surname name of fact author */
        last: PropTypes.string,
      }),
    }),
    /** Fascinating cat fact */
    text: PropTypes.string,
    /** Number of up-votes for fact */
    upvotes: PropTypes.number,
  })),
  /** Number of items per page */
  pageSize: PropTypes.number,
  /** Current search string */
  search: PropTypes.string,
};

export default Facts;