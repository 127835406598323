import STATUS from 'enum/status';
import {
  FETCH_FACTS_START,
  FETCH_FACTS_SUCCESS,
  FETCH_FACTS_FAILURE,
} from './facts.const';

const defaultState = {
  facts: [],
}

const factsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_FACTS_START:
      return {
        ...state,
        status: STATUS.LOADING,
      };

    case FETCH_FACTS_SUCCESS:
      return {
        ...state,
        facts: action.payload,
        status: STATUS.SUCCESS,
      };

    case FETCH_FACTS_FAILURE:
      return {
        ...state,
        status: STATUS.FAILURE,
      };

    default:
      return state;
  }
};

export { factsReducer as default };
