import React from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/portal';
import styles from './scss/modal.module.scss';


/** Renders a modal with a backdrop. */
const Modal = ({
  children,
  open,
}) => {
  if (!open) return null;

  return (
    <Portal>
      <div className={styles.backdrop}>
        <div className={styles.modal}>
          {children}
        </div>
      </div>
    </Portal>
  );
};

Modal.propTypes = {
  /** Modal content */
  children: PropTypes.node,
  /** Is modal open */
  open: PropTypes.bool,
};


export default Modal;
