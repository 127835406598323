import React from 'react';
import PropTypes from 'prop-types';
import styles from './scss/button.module.scss';

/** Button with text */
const Button = ({
  children,
  onClick,
}) => (
  <button className={styles.button} onClick={onClick}>
    {children}
  </button>
);

Button.propTypes = {
  /** Button content */
  children: PropTypes.node.isRequired,
  /** On click callback */
  onClick: PropTypes.func,
}

export default Button;
