import React from 'react';
import PropTypes from 'prop-types';
import styles from './scss/error-message.module.scss';

/** Error message */
const ErrorMessage = ({
  title,
  message,
}) => (
  <div className={styles.content}>
    {title && (
      <h1>{title}</h1>
    )}
    <p>{message}</p>
  </div>
);

ErrorMessage.propTypes = {
  /** Error title */
  title: PropTypes.string,
  /** Error message */
  message: PropTypes.string,
}

export default ErrorMessage;