import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import searchIcon from 'images/search-24px.svg';
import clearIcon from 'images/clear-24px.svg';
import SearchBox from 'components/search-box';
import Modal from 'components/modal';
import Button from 'components/button';
import styles from './scss/search-form.module.scss';

/** Search form */
const SearchForm = ({
  searchText,
  handleSearch,
}) => {
  const [value, setValue] = useState(searchText);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setValue(searchText);
  }, [searchText]);

  const submitSearch = (event) => {
    event.preventDefault();

    const shouldReset = value === searchText && value !== '';
    if (shouldReset) {
      setValue('');
      handleSearch('');
      return;
    }

    if (value === '' && !searchText) {
      setShowModal(true);
      return;
    }

    handleSearch(value);
  }

  return (
    <div className={styles.container}>
      <Modal open={showModal}>
        <p className={styles['modal-content']}>Looks like you forgot to enter text to search for.</p>
        <Button onClick={() => setShowModal(false)}>Oops</Button>
      </Modal>

      <form name="search" onSubmit={submitSearch}>
        <div className={styles.search}>
          <SearchBox placeholder="Find a cat fact" value={value} onChange={e => setValue(e.target?.value)} />
        </div>
        <div className={styles.button}>
          <button type="submit">
            {(searchText === '' || value !== searchText) ? (
              <img src={searchIcon} width="24" height="24" alt="search" />
            ) : (
              <img src={clearIcon} width="24" height="24" alt="clear" />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

SearchForm.defaultProps = {
  searchText: '',
  handleSearch: () => {},
}

SearchForm.propTypes = {
  /** Current search value */
  searchText: PropTypes.string,
  /** Search handler */
  handleSearch: PropTypes.func,
}


export default SearchForm;
