import { generatePath } from 'react-router-dom';

/** List of routes */
const routes = {
  FACTS: {
    get rule() {
      return '/:page(\\d+)?';
    },
    get make() {
      return (page = 1, search) => `${generatePath(this.rule, { page })}${search ? `?search=${encodeURIComponent(search.trim())}` : ''}`;
    },
  },
  FACT_DETAIL: {
    get rule() {
      return '/cat-fact/:factId';
    },
    get make() {
      return (factId = '', returnTo) => `${generatePath(this.rule, { factId })}${returnTo ? `?returnTo=${encodeURIComponent(returnTo)}` : ''}`;
    },
  },
};

export default routes;