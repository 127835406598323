import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import rootReducer from 'store/root-reducer/root-reducer.reducer';
import factReducer from 'store/facts/facts.reducer';


/**
 * Configure Redux dev tools
 */
const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

export const history = createBrowserHistory();

/**
 * Combine our reducers
 */
const reducers = combineReducers({
  facts: factReducer,
  router: connectRouter(history),
});

const router = routerMiddleware(history);

/**
 * Setup Redux store & apply middleware
 */
const store = createStore(
  rootReducer(reducers),
  composeEnhancers(applyMiddleware(
    thunk,
    router,
  )),
);

export { store as default, reducers };
