import { getJson } from 'helpers/api';
import {
  FETCH_FACTS_START,
  FETCH_FACTS_SUCCESS,
  FETCH_FACTS_FAILURE,
} from './facts.const';


const fetchFactsAction = () => (dispatch) => {
  dispatch({ type: FETCH_FACTS_START });

  return getJson('https://cat-fact.herokuapp.com/facts')
    .then((payload) => {
      dispatch({
        type: FETCH_FACTS_SUCCESS,
        payload: payload.all || [],
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_FACTS_FAILURE,
      });
    });
};

export default fetchFactsAction;
