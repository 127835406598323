import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import getReturnTo from 'helpers/get-return-to';
import routes from 'routes';
import Detail from '.';

const mapStateToProps = ({ facts = {}, router = {} }) => {
  const factList = facts.facts || []
  const factId = (matchPath(router.location?.pathname, { path: routes.FACT_DETAIL.rule }) || {}).params?.factId;
  const selectedFact = factList.find(item => item._id === factId);

  return ({
    fact: selectedFact,
    backLink: getReturnTo(router.location?.search) || routes.FACTS.make(),
  });
};

export default connect(mapStateToProps)(Detail);
