/**
 * Wrapper for Fetch Api GET
 *
 * @param {string} url url to fetch
 * @returns {Promise} Promise that resolves with JSON
 */
const getJson = (
  url,
) => {
  const init = {
    headers: { Accept: 'application/json' },
    method: 'GET',
  };

  return fetch(url, init)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      return response.json();
    })
    .catch(error => Promise.reject(error));
};

export {
  getJson,
};
