import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import routes from 'routes';
import styles from './scss/author.module.scss';

/** Fact detail screen */
const Author = ({
  name,
  surname,
}) => {
  return (
    <Link to={routes.FACTS.make(1, `${name} ${surname}`)} className={styles.author}>
      <div className={styles.img}>
        <img
          src={`https://eu.ui-avatars.com/api/?name=${name}+${surname}&rounded=true&background=ffffff&color=e91e63&format=svg`}
          width="64"
          height="64"
          alt={`${name} ${surname}`}
        />
      </div>

      <div className={styles.name}>
        <span>Added by</span><br />
        {name} {surname}
      </div>
    </Link>
  );
}

Author.propTypes = {
  /** First name of author */
  name: PropTypes.string,
  /** Surname name of author */
  surname: PropTypes.string,
};

export default Author;