import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { history } from 'store';
import routes from 'routes';
import ScrollToTop from 'components/scroll-to-top';
import Header from 'components/header';
import Loader from 'components/loader';
import NotFound from 'bundles/not-found';
import Error from 'components/error';
import ConnectedFacts from 'bundles/facts/connected';
import ConnectedDetail from 'bundles/detail/connected';
import styles from './scss/app.module.scss';

/** Main app container */
const App = ({
  loading,
  error,
  init,
}) => {
  useEffect(() => {
    init();
  }, [init]);

  return (
    <ConnectedRouter history={history}>
      <Fragment>
        <ScrollToTop />

        <div className={styles.app}>
          <Header />
        </div>

        {loading && (
          <Loader />
        )}

        {error && (
          <Error />
        )}

        {(!loading && !error) && (
          <Switch>
            <Route path={routes.FACTS.rule} exact component={ConnectedFacts} />
            <Route path={routes.FACT_DETAIL.rule} exact component={ConnectedDetail} />
            <Route component={NotFound} />
          </Switch>
        )}
      </Fragment>
    </ConnectedRouter>
  );
}

App.propTypes = {
  /** Is the component in loading state */
  loading: PropTypes.bool,
  /** Is the component in loading state */
  error: PropTypes.bool,
  /** Callback on component mount */
  init: PropTypes.func,
}

App.defaultProps = {
  loading: true,
  init: () => {},
}

export default App;
