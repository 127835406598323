import React from 'react';
import icon from 'images/cat-face.svg';
import { Link } from 'react-router-dom';
import styles from './scss/header.module.scss';

/** Main site header */
const Header = () => (
  <header className={styles.header}>
    <Link to="/">
      <h1><img src={icon} width="20" height="20" alt="Cat Icon" /> Cat Fact Finder</h1>
    </Link>
  </header>
);

export default Header;
